<template>
  <main>
    <div>
      <h1 class="text-2xl font-bold">POS Terminal Receipt</h1>
    </div>
    <div
      class="flex flex-2 content-between mt-5 justify-center items-center relative"
    >
      <div class="w-1/2 shadow-sm p-10">
        <h1 class=" font-bold text-center  ">
          Print Terminal Receipts
        </h1>
        <select
          class="mt-8 border w-2/4 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="bank"
          @change="onSelectBank($event)"
        >
          <option value="">Select a bank</option>
          <option value="GTB">GTB</option>
          <option value="Providus">Providus</option>
          <option value="Fidelity">Fidelity</option>
          <option value="Polaris">Polaris</option>
          <option value="Union">Union Bank</option>
          <option value="Wema">Wema Bank</option>
          <option value="Unity">Unity Bank</option>
        </select>
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="receipt_type"
          placeholder="Successful or Not?"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="rrn"
          placeholder="RRN"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="merchant"
          placeholder="Merchant"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="terminal_number"
          placeholder="Terminal No"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="transaction_date"
          placeholder="Transaction Date"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="location"
          placeholder="Location"
        />
        <input
          type="text"
          class="mt-8 border w-2/4 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="card_number"
          placeholder="Card Number"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="name"
          placeholder="Name"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="card_type"
          placeholder="Card Type"
        />
        <input
          type="text"
          class="mt-8 border w-2/4   ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="acct_type"
          placeholder="Account Type"
        />
        <input
          type="text"
          class="mt-8 border w-2/4  ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="exp_date"
          placeholder="Expiry Date"
        />
        <input
          type="text"
          class="mt-8 border w-2/4 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="stan"
          placeholder="STAN"
        />
        <input
          type="text"
          class="mt-8 border w-2/4 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="auth_no"
          placeholder="AUTH No"
        />
        <input
          type="text"
          class="mt-8 border w-2/4 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="response"
          placeholder="RESPONSE"
        />
        <input
          type="text"
          class="mt-8 border w-2/4 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="cardholder_auth"
          placeholder="CARDHOLDER AUTH"
        />
        <input
          type="text"
          class="mt-8 border w-2/4 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
          v-model="total_amount"
          placeholder="TOTAL AMOUNT"
        />

        <div class="mt-8 flex justify-end items-end">
          <button
            class="mr-4 border py-4 bg-gray-700 text-white px-6 rounded-md"
            @click="generateReport"
          >
            Download Receipt
          </button>
        </div>
      </div>
      <div class="flex w-1/2">
        <div class="w-full shadow-sm p-10">
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Terminal Receipt"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
            useCORS: true
            :html-to-pdf-options="htmlToPdfOptions"
          >
            <section slot="pdf-content">
              <div id="content-container">
                <!-- <div id="_bg__receipts"></div> -->
                <img
                  class="fidelity_bank_nigeria_logo_1"
                  src="@/assets/images/POS/polaris.png"
                  v-if="polaris"
                  style="display:block;margin:auto;"
                  crossorigin="anonymous | use-credentials"
                />
                <img
                  class="fidelity_bank_nigeria_logo_1"
                  src="@/assets/images/POS/fidelity.png"
                  v-if="fidelity"
                  style="display:block;margin:auto;"
                  crossorigin="anonymous | use-credentials"
                />
                <img
                  class="fidelity_bank_nigeria_logo_1"
                  src="@/assets/images/POS/gtb.png"
                  v-if="gtb"
                  style="display:block;margin:auto;"
                  crossorigin="anonymous | use-credentials"
                />
                <img
                  class="fidelity_bank_nigeria_logo_1"
                  src="@/assets/images/POS/union.png"
                  v-if="union"
                  style="display:block;margin:auto;"
                  crossorigin="anonymous | use-credentials"
                />
                <img
                  class="fidelity_bank_nigeria_logo_1"
                  src="@/assets/images/POS/wema.png"
                  v-if="wema"
                  style="display:block;margin:auto;"
                  crossorigin="anonymous | use-credentials"
                />
                 <img
                  class="fidelity_bank_nigeria_logo_1"
                  src="@/assets/images/POS/providus.png"
                  v-if="providus"
                  style="display:block;margin:auto;"
                  crossorigin="anonymous | use-credentials"
                />
                 <img
                  class="fidelity_bank_nigeria_logo_1"
                  src="@/assets/images/POS/unity.png"
                  v-if="unity"
                  style="display:block;margin:auto;"
                  crossorigin="anonymous | use-credentials"
                />
                <div
                  id="merchant_copy"
                  style="text-align:center;margin-top:10px"
                >
                  MERCHANT COPY
                </div>
                <div id="frame_1">
                  <div id="terminal_no___2035cz86">
                    TERMINAL NO : {{ terminal_number }}
                  </div>
                  <div id="transaction_date___2035cz86">
                    TRANSACTION DATE : {{ transaction_date }}
                  </div>
                </div>

                <div id="reprint" style="margin-top:10px">
                  REPRINT
                </div>
                <div
                  id="purchase_approved"
                  style="padding-left:100px;margin:10px;"
                >
                  {{ receipt_type || "PURCHASE APPROVED" }}
                </div>
                <div id="frame_2">
                  <div id="merchant___traction_apps_ltd">
                    MERCHANT : {{ merchant }}
                  </div>
                  <div id="location___121b___association_road__ikoyi_lagos">
                    LOCATION : {{ location }}
                  </div>
                  <div id="card_number___55337646_______4567">
                    CARD NUMBER : {{ card_number }}
                  </div>
                  <div id="name___traction_apps_ltd">NAME : {{ name }}</div>
                  <div id="card_type___mastercard">
                    CARD TYPE : {{ card_type }}
                  </div>
                  <div id="account_type__current">
                    ACCOUNT TYPE: {{ acct_type }}
                  </div>
                  <div id="expiry_date__24_05">EXPIRY DATE: {{ exp_date }}</div>
                  <div id="stan___000325">STAN : {{ stan }}</div>
                  <div id="auth_no__000325">AUTH NO: {{ auth_no }}</div>
                  <div id="ref_no___213345674894">REF NO : {{ rrn }}</div>
                  <div id="response___completed_successfully">
                    RESPONSE : {{ response }}
                  </div>
                  <div id="cardholder_auth___pin">
                    CARDHOLDER AUTH : {{ cardholder_auth }}
                  </div>
                  <div
                    id="ptsp_detail___info_tractionappssupport_co__01342999999"
                  >
                    PTSP DETAIL : info@citi-serve.com | 013429999
                  </div>
                </div>
                <div id="frame_3" class="font-bold" style="font-size:14px">
                  TOTAL AMOUNT : NGN {{ total_amount }}
                </div>
                <img
                  src="@/assets/images/terminals/citiserve.png"
                  id="logo_1"
                  style="margin: 30px 0px;"
                />
                <div id="please_retain_your_receipt_thank_you">
                  Please retain your receipt<br />Thank You
                </div>
                <div id="" class="" style="padding-left:123px;font-size:10px">
                  Powered by CitiSERVE<br />Version 1.0.16
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      moment,
      terminal_number: null,
      transaction_date: null,
      merchant: null,
      bank: null,
      selectedBank: null,

      receipt_type: null,

      wema: false,
      union: false,
      polaris: false,
      fidelity: false,
      gtb: false,
      providus: false,
      unity: false,

      rrn: null,
      merchant: null,
      terminal: null,
      location: null,
      card_number: null,
      name: null,
      card_type: null,
      acct_type: null,
      exp_date: null,
      stan: null,
      response: null,
      auth_no: null,
      cardholder_auth: null,
      total_amount: null,
    };
  },
  computed: {
    formatDocuments() {
      return this.documents;
    },
    htmlToPdfOptions() {
        return {
          margin: 0,
      
          filename: `Terminal Receipt`,
      
          image: {
              type: 'jpeg', 
              quality: 0.98
          },
      
          enableLinks: false,
      
          html2canvas: {
              scale: 1,
              dpi: 300,
              letterRendering: true,
              useCORS: true
          },
      
          jsPDF: {
              unit: 'in',
              format: 'a4',
              orientation: 'portrait'
          }
      }
    }
  },
  async mounted() {},
  async created() {},
  methods: {
    
    onSelectBank(event) {
      this.wema = this.union = this.polaris = this.gtb = this.fidelity = this.providus = this.unity = "";
      this.selectedBank =
        event.target.value === "Wema"
          //? "https://main.tractionapps.co/wp-content/uploads/2021/09/wema-bank.png"
          ? this.wema=true//"@/assets/images/POS/wema.png"
          : event.target.value === "Union"
          ? this.union=true//"https://main.tractionapps.co/wp-content/uploads/2021/09/4124311821062255069UnionBankNigeriaLogo1.svg"
          : event.target.value === "Polaris"
          ? this.polaris = true//"https://main.tractionapps.co/wp-content/uploads/2021/09/8077012570881853236PolarisBankLogo1.svg"
          : event.target.value === "GTB"
          ? this.gtb = true //"https://main.tractionapps.co/wp-content/uploads/2021/09/8947268348557637176GuarantyTrustBankLogo1.svg"
          : event.target.value === "Fidelity"
          ? this.fidelity = true //"https://main.tractionapps.co/wp-content/uploads/2021/09/1001282656534825677FidelityBankNigeriaLogo1.svg"
          : event.target.value === "Providus"
          ? this.providus = true
          : event.target.value === "Unity"
          ? this.unity = true
          : "";
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");
#content-container {
  display: block;
  position: relative;
  width: 360px;
  margin-left: auto;
  margin-right: auto;
}

#_bg__receipts {
  top: 0px;
  left: 0px;
  width: 360px;
  height: 929px;
  background: rgba(255, 255, 255, 1);
}

#frame_1 {
  top: 138px;
  left: 19px;
  width: 199px;
  height: 41px;
}

#terminal_no___2035cz86 {
  top: 0px;
  left: 0px;
  width: 172px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  color: #333333;
}

#transaction_date___2035cz86 {
  top: 26px;
  left: 0px;
  width: 210px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  color: #333333;
}

#merchant_copy {
  top: 93.64px;
  left: 101px;
  width: 169px;
  height: 39.03px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#fidelity_bank_nigeria_logo_1 {
  top: 12px;
  left: 148px;
  width: 64px;
  height: 64px;
}

#vector {
  top: 47.9px;
  left: 5.5px;
  width: 6.24px;
  height: 10.85px;
}

#vector_ek1 {
  top: 47.15px;
  left: 12.79px;
  width: 3.06px;
  height: 11.59px;
}

#vector_ek2 {
  top: 46.39px;
  left: 17.26px;
  width: 8.77px;
  height: 12.57px;
}

#vector_ek3 {
  top: 50.37px;
  left: 26.27px;
  width: 7.17px;
  height: 8.59px;
}

#vector_ek4 {
  top: 46.39px;
  left: 34.37px;
  width: 3.06px;
  height: 12.36px;
}

#vector_ek5 {
  top: 47.15px;
  left: 38.95px;
  width: 3.06px;
  height: 11.59px;
}

#vector_ek6 {
  top: 49.19px;
  left: 43.71px;
  width: 5.71px;
  height: 9.77px;
}

#vector_ek7 {
  top: 50.37px;
  left: 50.58px;
  width: 7.92px;
  height: 12.74px;
}

#vector_ek8 {
  top: 0.9px;
  left: 9.01px;
  width: 41.23px;
  height: 37.12px;
}

#vector_ek9 {
  top: 2.59px;
  left: 12.89px;
  width: 42.1px;
  height: 38.02px;
}

#purchase_approved {
  top: 221px;
  left: 127px;
  width: 214px;
  height: 56px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#frame_3 {
  top: 675px;
  left: 17px;
  width: 254px;
  height: 33px;
}

#total_amount__ {
  top: 8px;
  left: 8px;
  width: 137px;
  height: 27px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#ngn_5000_00 {
  top: 8px;
  left: 150px;
  width: 108px;
  height: 27px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#frame_2 {
  /* top: 267px; */
  left: 17px;
  width: 323px;
  /* height: 408px; */
}

#merchant___traction_apps_ltd {
  top: 14px;
  left: 14px;
  width: 224px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#location___121b___association_road__ikoyi_lagos {
  top: 43px;
  left: 14px;
  width: 304px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#card_number___55337646_______4567 {
  top: 72px;
  left: 14px;
  width: 244px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#name___traction_apps_ltd {
  top: 101px;
  left: 14px;
  width: 190px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#card_type___mastercard {
  top: 130px;
  left: 14px;
  width: 182px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#account_type__current {
  top: 159px;
  left: 14px;
  width: 178px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#expiry_date__24_05 {
  top: 188px;
  left: 14px;
  width: 136px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#stan___000325 {
  top: 217px;
  left: 14px;
  width: 101px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#auth_no__000325 {
  top: 246px;
  left: 14px;
  width: 120px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#ref_no___213345674894 {
  top: 275px;
  left: 14px;
  width: 154px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#response___completed_successfully {
  top: 304px;
  left: 14px;
  width: 240px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#cardholder_auth___pin {
  top: 333px;
  left: 14px;
  width: 173px;
  height: 23px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#ptsp_detail___info_tractionappssupport_co__01342999999 {
  top: 362px;
  left: 14px;
  width: 357px;
  height: 40px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  color: #333333;
}

#reprint {
  top: 199px;
  left: 28px;
  width: 77px;
  height: 27px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}

#logo_1 {
  top: 731px;
  left: 24px;
  width: 300px;
  height: 61px;
}

#please_retain_your_receipt_thank_you {
  top: 821px;
  left: 21px;
  width: 167px;
  height: 38px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  color: #333333;
}

#powered_by_citiserve_version_1_0_16 {
  top: 877px;
  left: 123px;
  width: 122px;
  height: 31px;
  overflow: hidden;
  font-family: Montserrat;
  font-size: 10px;
  text-align: left;
  color: #333333;
}
</style>
